<style>
    .etitle {
        display: flex;
        flex-direction: column;
        padding: 10px 10px 0 10px;
    }

    .et1 {

        display: flex;
        justify-content: space-between;
    }

    .hitem {
        background-color: white;
        width: 49%;
        margin-bottom: 10px;
        border-radius: 6px;
        padding: 12px 16px 13px 16px;
    }

    .hitem1 {
        font-weight: 800;
        font-size: 15px;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .hitem1 img {
        height: 48px;
        margin-right: 20px;

    }

    .lxnumber {
        display: flex;
        color: #666666;
        font-size: 12px;
        justify-content: space-between;
        margin-top: 5px;

    }

    .econ {
        color: white;
        display: flex;
        flex-direction: column;
        padding: 5px 10px 10px 10px;
        font-size: 14px;
        font-weight: bold;
    }

    .eitem {
        display: flex;
        color: #666666;
        background-color: white;
        padding: 12px;
        border-radius: 6px;
        margin-top: 10px;
        font-size: 12px;
    }

    .eitem img {
        height: 46px;
    }

    .dimg {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .eitem2 {
        margin-left: 20px;
        width: 100%;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        justify-content: center;
        letter-spacing: 1px;
        color: #666666;
        font-size: 12px;
    }

    .uinfo {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 8px;
    }

    .uname {
        color: #333333;
        font-size: 14px;
        font-weight: bold;
    }

    .score {
        color: #265599;
        font-weight: 600;
        font-size: 14px;

    }

    .contentexamAll {
        background: linear-gradient(to top, #3BC0E1, #265599, #265599);
        padding: 0 !important;
        min-height: 100vh;

    }

    .contentexam {
        background: linear-gradient(to top, #3BC0E1, #265599, #265599);
        padding: 0 !important;
        min-height: 100vh;
        max-width: 750px;
        width: 100%;


    }

    @media screen and (min-width: 751px) {
        .contentexam {
            margin-left: calc((100% - 750px) / 2);
        }
    }

    .lineb {
        display: flex;
    }

    .errorMessage {
        background-color: #000000;
        opacity: 0.65;
        position: absolute;
        top: 0;
        left: 0;
        color: #fecd00;
        text-align: center;
        width: 100%;
        font-size: 12px;
        line-height: 50px;

    }

    .bround {
        border-radius: 6px;
    }
</style>
<template>

    <div class="contentexamAll">
        <div class="contentexam" v-if="wxShow">
            <div class="etitle ">
                <img class="bround" src="../../assets/exam2.jpg" style="width: 100%"/>
            </div>
            <div v-if="ut" class="errorMessage" @click="go_login">
                练习前请先完成登录!
            </div>
            <!---模块-->
            <div class="etitle">
                <div class="et1">
                    <div class="hitem">
                        <div class="hitem1" @click="exam(1,1)">
                            <div>
                                <img src="../../assets/icon_zcfg.png">
                            </div>
                            <div>政策法规</div>
                        </div>
                        <div class="lxnumber">
                            <div @click="exam(1,2)" class="lineb">答错{{gc[0].error_num}}道
                                <el-button style="border: none;margin: 0;padding: 0;"
                                           icon="el-icon-caret-right"></el-button>
                            </div>
                            <div>已练习{{gc[0].que_num}}道</div>
                        </div>
                    </div>
                    <div class="hitem">
                        <div class="hitem1" @click="exam(2,1)">
                            <div>
                                <img src="../../assets/icon_mmjs.png">
                            </div>
                            <div>密码技术</div>
                        </div>
                        <div class="lxnumber">
                            <div @click="exam(2,2)" class="lineb">答错{{gc[1].error_num}}道
                                <el-button style="border: none;margin: 0;padding: 0;"
                                           icon="el-icon-caret-right"></el-button>
                            </div>
                            <div>已练习{{gc[1].que_num}}道</div>
                        </div>
                    </div>
                </div>
                <div class="et1">
                    <div class="hitem">
                        <div class="hitem1" @click="exam(3,1)">
                            <div>
                                <img src="../../assets/icon_mmcp.png">
                            </div>
                            <div>密码产品</div>
                        </div>
                        <div class="lxnumber">
                            <div @click="exam(3,2)" class="lineb">答错{{gc[2].error_num}}道
                                <el-button style="border: none;margin: 0;padding: 0;"
                                           icon="el-icon-caret-right"></el-button>
                            </div>
                            <div>已练习{{gc[2].que_num}}道</div>
                        </div>
                    </div>
                    <div class="hitem">
                        <div class="hitem1" @click="exam(4,1)">
                            <div>
                                <img src="../../assets/icon_mmpg.png">
                            </div>
                            <div>密码评估</div>
                        </div>
                        <div class="lxnumber">
                            <div @click="exam(4,2)" class="lineb">答错{{gc[3].error_num}}道
                                <el-button style="border: none;margin: 0;padding: 0;"
                                           icon="el-icon-caret-right"></el-button>
                            </div>
                            <div>已练习{{gc[3].que_num}}道</div>
                        </div>
                    </div>
                </div>
            </div>
            <!--排行榜-->
            <div class="econ">
                <div>排行榜Top20</div>
                <div class="eitem" v-for="item in score">
                    <div class="dimg">
                        <img src="../../assets/userA.png">
                    </div>
                    <div class="eitem2">
                        <div class="uinfo">
                            <div class="uname">{{item.user_name}}</div>
                            <div>最高<span class="score">{{item.score}}</span>分（答题{{item.exam_num}}次）</div>
                        </div>
                        <div>更新时间:{{item.created_at}}</div>
                    </div>
                </div>
            </div>

        </div>
        <div v-else>
            <img src="../../assets/wbg.jpg" style="width: 100vw;"/>
        </div>
    </div>
</template>
<script>
    import request from "../../plugins/axios.js";

    export default {
        data() {
            return {
                user: "",
                gc: [
                    {user_num: 0, que_num: 0, error_num: 0},
                    {user_num: 0, 'que_num': 0},
                    {user_num: 0, 'que_num': 0},
                    {user_num: 0, 'que_num': 0}
                ],
                score: [],
                wxShow: false,
                ut: true
            };
        },
        components: {},
        mounted() {
            let that = this
            //设置背景动画播放速度
            document.title = "密码知识题库"
            var userAgent = navigator.userAgent.toLowerCase();
            // 判断是否在微信中打开
            if (userAgent.indexOf('micromessenger') !== -1) {
                that.wxShow = true;
            } else {
                that.$message.error('请在微信中打开');
                that.wxShow = false;
                return false
            }
            let u = localStorage.getItem('u')
            if (u) {
                that.ut = false
            }
            that.gcData()
            that.gcScore()

        },
        methods: {
            go_login() {
                this.$router.push("/login");
            },
            gcData() {
                let that = this
                request({
                    url: "/mn/e/gc",
                    data: {},
                }).then((ret) => {
                    if (ret.code == 200) {
                        if (ret.data.length > 0) {
                            that.gc = ret.data
                        }

                    }
                })
            },
            gcScore() {
                let that = this
                request({
                    url: "/mn/e/score",
                    data: {},
                }).then((ret) => {
                    if (ret.code == 200) {
                        that.score = ret.data
                    }
                })
            },
            //去测试
            exam(group, error) {

                let that = this
                let u = localStorage.getItem('u')
                if (!u) {
                    localStorage.setItem('lurl', '/exam')
                    this.$router.push("/login");
                } else {
                    localStorage.removeItem('lurl')
                }

                localStorage.setItem('error', error)
                localStorage.setItem('examtg', group)


                let token = JSON.parse(u).token
                this.$router.push("/answert");
            }
        },
    };
</script>
